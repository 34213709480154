<template>
    <div>
        <h1>Under Development...</h1>
    </div>
</template>

<script>
    export default {
        name      : "base-access-page-component",
        props     : {},
        model: {
            event: 'updateModel'
        },
        components: {},
        data()      {
            return {}
        },
        computed  : {},
        methods   : {},
        watch     : {}
    }
</script>

<style scoped>

</style>